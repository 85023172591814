import { useCallback, useState } from 'react'
import { LegacyStack, Card, BlockStack, Combobox, Listbox, Icon, Tag, Text } from '@shopify/polaris'
import { SearchIcon } from '@shopify/polaris-icons'
import { convertToAmPmFormat } from '../../utils'

const Options = ({ selectionOptions, setSelectionOptions, setSelectedOptions, selectedOptions, deSelectionOptions, comboLabel, comboPlaceHolder, allowMultiple, isTime }) => {
  const [inputValue, setInputValue] = useState('')
  const escapeSpecialRegExCharacters = useCallback(
    (value) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    []
  )

  const formattedTimeTag = (tag) => {
    if (!isTime) return tag
    return convertToAmPmFormat(tag)
  }

  const optionsMarkup =
  selectionOptions.length > 0
    ? selectionOptions.map((option) => {
      const { label, value } = option

      return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOptions.includes(value)}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
      )
    })
    : null

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selectedOptions]
      options.splice(options.indexOf(tag), 1)
      setSelectedOptions(options)
    },
    [selectedOptions, setSelectedOptions]
  )

  const tagsMarkup = selectedOptions.map((option) => (
        <Tag key={`option-${option}`} onRemove={removeTag(option)}>
          {formattedTimeTag(option)}
        </Tag>
  ))

  const updateText = useCallback(
    (value) => {
      setInputValue(value)

      if (value === '') {
        setSelectionOptions(deSelectionOptions)
        return
      }
      const filterRegex = escapeSpecialRegExCharacters(value)
      const resultOptions = deSelectionOptions.filter((option) =>
        option.label.match(filterRegex)
      )
      setSelectionOptions(resultOptions)
    },
    [deSelectionOptions, escapeSpecialRegExCharacters, setSelectionOptions]
  )

  const updateSelection = useCallback(
    (selected) => {
      if (!allowMultiple) {
        setSelectedOptions([selected])
        updateText('')
        return
      }
      if (selectedOptions.includes(selected)) {
        setSelectedOptions(selectedOptions.filter((option) => { return option !== selected }))
      } else {
        setSelectedOptions([...selectedOptions, selected])
      }

      updateText('')
    },
    [selectedOptions, updateText, setSelectedOptions, allowMultiple]
  )

  return (
        <BlockStack gap='400'>
        <Text>{comboLabel}</Text>
        <Combobox
            allowMultiple={allowMultiple}
            activator={
              <Combobox.TextField
                prefix={<Icon source={SearchIcon} />}
                onChange={updateText}
                labelHidden
                value={inputValue}
                placeholder={comboPlaceHolder}
                autoComplete="off"
              />
            }
          >
            {optionsMarkup
              ? (
              <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
                )
              : null}
          </Combobox>
              <Card>
                <LegacyStack>{tagsMarkup}</LegacyStack>
              </Card>
          </BlockStack>
  )
}

export default Options
