import { format, startOfISOWeekYear } from 'date-fns'
import { MD5 } from 'crypto-js'
export function getCookieDomain () {
  let domainParts
  const currentDomain = window.location.hostname
  domainParts = currentDomain.split('.')
  if (domainParts.length > 2) domainParts = domainParts.slice(-2)
  return `.${domainParts.join('.')}`
}

export function objectToId (object) {
  const string = JSON.stringify(object)
  const hash = MD5(string).toString()
  return hash.match(/(.{8})(.{4})(.{4})(.{4})(.{12})/).slice(1).join('-')
}

export function getFormattedDates ({ selectedYear, selectedGrouping = 'weekly' }) {
  let startDate, endDate
  if (selectedGrouping === 'monthly') {
    startDate = format(new Date(+selectedYear, 0, 1), 'yyyy-MM-dd')
    endDate = format(new Date(+selectedYear + 1, 0, 1), 'yyyy-MM-dd')
  } else {
    startDate = format(startOfISOWeekYear(new Date(selectedYear, 0, 31)), 'yyyy-MM-dd')
    endDate = format(startOfISOWeekYear(new Date(+selectedYear + 1, 0, 31)), 'yyyy-MM-dd')
  }
  return { startDate, endDate }
}

export async function setDefaultFilterSelectedValues ({ filterName, unselectedValues, selectedValues, gridApi }) {
  let filteredValues
  const filterInstance = await gridApi.current.getColumnFilterInstance(filterName)
  if (unselectedValues) filteredValues = filterInstance.getValues().filter(value => !unselectedValues.includes(value))
  if (selectedValues) filteredValues = filterInstance.getValues().filter(value => selectedValues.includes(value))
  filterInstance.setModel({ values: filteredValues })
  gridApi.current.onFilterChanged()
}

export const hoursOptions = []
for (let i = 0; i < 24; i++) {
  hoursOptions.push({ label: convertToAmPmFormat(String(i)), value: String(i) })
}

export function translateCronJobFormat (selectedDayOptions, selectedHourOptions) {
  let cronSchedule = ''

  // Convert selected days to cron format
  if (selectedDayOptions.length > 0) {
    const days = selectedDayOptions.map((day) => {
      switch (day) {
        case 'Monday':
          return '1'
        case 'Tuesday':
          return '2'
        case 'Wednesday':
          return '3'
        case 'Thursday':
          return '4'
        case 'Friday':
          return '5'
        case 'Saturday':
          return '6'
        case 'Sunday':
          return '0'
        default:
          return ''
      }
    })
    cronSchedule += days.join(',')
  } else {
    cronSchedule += '*'
  }

  cronSchedule += ' '

  // Convert selected hours to cron format
  if (selectedHourOptions.length > 0) {
    cronSchedule += selectedHourOptions.join(',')
  } else {
    cronSchedule += '*'
  }

  return createCronSchedule(cronSchedule)
}

export function createCronSchedule (daysHourString) {
  const [days, hour] = daysHourString.split(' ')
  return `0 ${hour} * * ${days}`
}

export function getCronDays (cronFormat) {
  const days = cronFormat.split(' ')[4].split(',')
  const dayStrings = days.map((day) => {
    switch (day) {
      case '0':
        return 'Sunday'
      case '1':
        return 'Monday'
      case '2':
        return 'Tuesday'
      case '3':
        return 'Wednesday'
      case '4':
        return 'Thursday'
      case '5':
        return 'Friday'
      case '6':
        return 'Saturday'
      default:
        return ''
    }
  })
  return dayStrings
}

export function getCronHour (cronFormat) {
  const hour = cronFormat.split(' ')[1]
  return hour
}

export function convertToAmPmFormat (time) {
  const hour = parseInt(time, 10)
  let amPmTime

  if (hour === 0) {
    amPmTime = '12:00 AM'
  } else if (hour < 12) {
    amPmTime = `${hour}:00 AM`
  } else if (hour === 12) {
    amPmTime = '12:00 PM'
  } else {
    amPmTime = `${hour - 12}:00 PM`
  }

  return amPmTime
}
