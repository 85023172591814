import axios from 'axios'
import config from '../config'
import { getSessionToken } from '@shopify/app-bridge/utilities'

const axiosInstance = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  withCredentials: true
})

// Axios instance with custom defaults
const axiosWithCredentials = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  withCredentials: true, // Enable credentials globally
  headers: {
    'Content-Type': 'application/json'
  }
})

const backendRequest = async ({ method, url, appBridge, data }) => {
  let headers
  if (appBridge) {
    const sessionToken = await getSessionToken(appBridge)
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
      'X-Shopify-App': process.env.REACT_APP_SHOPIFY_APP
    }
  } else {
    headers = {
      'Content-Type': 'application/json'
    }
  }
  return axiosInstance.request({ method, url, data, headers })
}

// Instance without credentials
const axiosWithoutCredentials = axios.create({
  baseURL: `${config.apiBaseUrl}`
})

export { axiosWithCredentials, axiosWithoutCredentials, backendRequest }
