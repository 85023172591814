import { AppLink, NavigationMenu } from '@shopify/app-bridge/actions'

function getBomMenuItems ({ appBridge, t }) {
  const bundlesLink = AppLink.create(appBridge, {
    label: t('sidebar.bundles'),
    destination: '/bundles'
  })
  return [bundlesLink]
}

function getReplenMenuItems ({ appBridge, t }) {
  const replenishmentViewLink = AppLink.create(appBridge, {
    label: t('sidebar.replenishment'),
    destination: '/replenishment'
  })
  // const welcomeViewLink = AppLink.create(appBridgeInitial, {
  //   label: 'Welcome',
  //   destination: '/welcome'
  // })
  const settingViewLink = AppLink.create(appBridge, {
    label: t('sidebar.setting'),
    destination: '/setting'
  })
  const planViewLink = AppLink.create(appBridge, {
    label: t('sidebar.plan'),
    destination: '/plan-selection'
  })
  return [
    replenishmentViewLink,
    planViewLink,
    settingViewLink
  ]
}

export function createMenu ({ appBridge, t }) {
  let items = []
  switch (process.env.REACT_APP_SHOPIFY_APP) {
    case 'bom':
      items = getBomMenuItems({ appBridge, t })
      break
    default:
      items = getReplenMenuItems({ appBridge, t })
  }
  NavigationMenu.create(appBridge, {
    items
  })
}
