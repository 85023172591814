import { useTranslation } from 'react-i18next'
import { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import { Page, Button, Card, Text, Box, BlockStack, TextField, InlineGrid, Toast, Frame } from '@shopify/polaris'
import Options from '../../components/shopify/Options'
import { useNavigate } from 'react-router-dom'
import { DataUpdateContext } from '../../context/context'
import { backendRequest } from '../../utils/axiosInstance'
import { getCronDays, getCronHour, hoursOptions, objectToId, translateCronJobFormat } from '../../utils'

export const SettingView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [active, setActive] = useState(false)
  const [activeError, setActiveError] = useState(false)
  const toggleActive = useCallback(() => setActive((active) => !active), [])
  const errorToggleActive = useCallback(() => setActiveError((activeError) => !activeError), [])
  const toastMarkup = active
    ? (
      <Toast content="Settings saved" onDismiss={toggleActive} />
      )
    : null
  const errorToastMarkup = activeError
    ? (
      <Toast content="Server error" error onDismiss={toggleActive} />
      )
    : null

  const [selectedDayOptions, setSelectedDayOptions] = useState([])
  const [selectedHourOptions, setSelectedHourOptions] = useState([])
  const [emailAddress, setEmailAddress] = useState('')

  const { companyId, appBridge } = useContext(DataUpdateContext)

  const handleNavigateToPlanSelection = () => {
    navigate('/plan-selection')
  }

  const handleEmailAddressChange = useCallback((value) => {
    setEmailAddress(value)
  }, [])

  const handleSaveSettings = () => {
    const saveData = async () => {
      const schedule = translateCronJobFormat(selectedDayOptions, selectedHourOptions)
      try {
        const response = await backendRequest({ method: 'PUT', url: `/job/cron-jobs/${objectToId({ companyId, jobType: 'replenishment_alert' })}`, data: { job_type: 'replenishment_alert', company_id: companyId, schedule, active: true, data: { email: emailAddress } }, appBridge })
        if (response.status === 200) {
          setSelectedDayOptions(getCronDays(response.data.schedule))
          setSelectedHourOptions([getCronHour(response.data.schedule)])
          setEmailAddress(response.data.data.email)
          toggleActive()
        }
      } catch (error) {
        errorToggleActive()
      }
    }
    saveData()
  }

  const hourDeSelectionOptions = useMemo(() => hoursOptions, [])
  const dayDeSelectionOptions = useMemo(
    () => [
      { label: t('days.Monday'), value: t('days.Monday') },
      { label: t('days.Tuesday'), value: t('days.Tuesday') },
      { label: t('days.Wednesday'), value: t('days.Wednesday') },
      { label: t('days.Thursday'), value: t('days.Thursday') },
      { label: t('days.Friday'), value: t('days.Friday') },
      { label: t('days.Saturday'), value: t('days.Saturday') },
      { label: t('days.Sunday'), value: t('days.Sunday') }
    ],
    [t]
  )
  const [daySelectionOptions, setDaySelectionOptions] = useState(dayDeSelectionOptions)
  const [hourSelectionOptions, setHourSelectionOptions] = useState(hourDeSelectionOptions)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!companyId) {
          console.error('Missing company ID in the URL')
          return
        }
        const response = await backendRequest({ method: 'GET', url: `/job/cron-jobs?company_id=${companyId}&job_type=replenishment_alert&fields=uuid,data,active,schedule`, data: {}, appBridge })
        if (response.status === 200 && response.data.total > 0 && response.data.cronJobs[0].active === true) {
          setSelectedDayOptions(getCronDays(response.data.cronJobs[0].schedule))
          setSelectedHourOptions([getCronHour(response.data.cronJobs[0].schedule)])
          setEmailAddress(response.data.cronJobs[0].data.email)
        } else {
          console.error('Failed to confirm subscription status')
        }
      } catch (error) {
        console.error('An error occurred while processing the alerts return', error)
      }
    }
    fetchData()
  }, [companyId, appBridge, t])

  return (
    <Frame>
    <Page
    title={t('sidebar.setting')}
    divider
    primaryAction={{ content: 'Save Settings', disabled: false, onAction: () => handleSaveSettings() }}
    >
    <BlockStack gap={{ xs: '800', sm: '400' }}>
      <InlineGrid columns={{ xs: '1fr', md: '2fr 5fr' }} gap='400'>
        <Box
          as='section'
          paddingInlineStart={{ xs: 400, sm: 0 }}
          paddingInlineEnd={{ xs: 400, sm: 0 }}
        >
          <BlockStack gap='400'>
            <Text as='h3' variant='headingMd'>
              Alerts
            </Text>
            <Text as='p' variant='bodyMd'>
              Customize your alerts to get notified when inventory is running low
            </Text>
          </BlockStack>
        </Box>
        <Card roundedAbove='sm'>
          <BlockStack gap='400'>
            <TextField
              label='Email'
              value={emailAddress}
              onChange={handleEmailAddressChange}
            />
            {/* <Text>{emailAddress}</Text> */}
          </BlockStack>
        </Card>
      </InlineGrid>
      <InlineGrid columns={{ xs: '1fr', md: '2fr 5fr' }} gap='400'>
        <Box
          as='section'
          paddingInlineStart={{ xs: 400, sm: 0 }}
          paddingInlineEnd={{ xs: 400, sm: 0 }}
        >
        </Box>
        <Card roundedAbove='sm'>
          <BlockStack gap='400'>
          <Options
            selectionOptions={daySelectionOptions}
            setSelectionOptions={setDaySelectionOptions}
            setSelectedOptions={setSelectedDayOptions}
            selectedOptions={selectedDayOptions}
            deSelectionOptions={dayDeSelectionOptions}
            comboLabel="Select days"
            comboPlaceholder="Select the days"
            allowMultiple={true}
            isTime={false}
          />
          <Options
            selectionOptions={hourSelectionOptions}
            setSelectionOptions={setHourSelectionOptions}
            setSelectedOptions={setSelectedHourOptions}
            selectedOptions={selectedHourOptions}
            deSelectionOptions={hourDeSelectionOptions}
            comboLabel="Select the hour of the day"
            comboPlaceholder="Select the hours"
            allowMultiple={false}
            isTime={true}
          />
          </BlockStack>
        </Card>
      </InlineGrid>
      <InlineGrid columns={{ xs: '1fr', md: '2fr 5fr' }} gap='400'>
        <Box
          as='section'
          paddingInlineStart={{ xs: 400, sm: 0 }}
          paddingInlineEnd={{ xs: 400, sm: 0 }}
        >
          <BlockStack gap='400'>
            <Text as='h3' variant='headingMd'>
              Subscriptions
            </Text>
            <Text as='p' variant='bodyMd'>
              Manage your subscriptions
            </Text>
          </BlockStack>
        </Box>
        <Card roundedAbove='sm'>
          <BlockStack gap='400'>
          <Button onClick={handleNavigateToPlanSelection}>{t('settings.plan')}</Button>
          </BlockStack>
        </Card>
      </InlineGrid>
    </BlockStack>
    <BlockStack gap='400'>
      </BlockStack>
      {toastMarkup}
      {errorToastMarkup}
    </Page>
    </Frame>
  )
}
